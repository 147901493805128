export const homeObjOne = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    topLine: 'A Sales Network',
    headline: 'Commission Based Sales Reps for SaaS and Bookkeepers',
    description:
      'We help small businesses increase their sales. Our team of marketing and sales specialist can help you achieve your revenue goals purely on commission.',
    buttonLabel: 'Book a Demo',
    imgStart: '',
    img: require('../../images/svg-1.svg'),
    alt: 'Credit Card',
    start: ''
  };
  
  export const homeObjTwo = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Marketing Included',
    headline: 'Complimentary Ads, Web Developement, Social Media Management & More',
    description:
      "Our team of marketing specialist assist with website development, social media management, ad creation, and more completely free.",
    buttonLabel: 'Setup a Call Now',
    imgStart: '',
    img: require('../../images/svg-2.svg'),
    alt: 'Vault',
    start: ''
  };
  

  
  export const homeObjFour = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    topLine: 'A Complete Solution',
    headline: 'All your sales & marketing needs',
    description:
      'You will never have to worry about the ins and ours of sales or marketing again. From cold calling to price negotiations, with a powerful and dedicated team behind you to handle it all.',
    buttonLabel: 'Learn More',
    imgStart: 'start',
    img: require('../../images/svg-3.svg'),
    alt: 'Vault',
    start: 'true'
  };